import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box, IBox } from '../../Box'
import { IRadioControl, IDot, IInput } from './types'
import { setTransition } from '../../../utility'

export const RadioControl: FC<React.PropsWithChildren<IRadioControl>> = ({
  isChecked = false,
  value,
  id,
  name,
  isError,
  isDisabled,
  isHover,
  isRequired,
  onChange,
  forwardedAs = 'label',
  ...rest
}) => {
  const [isFocused, setIsFocus] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)

  const attributes = {
    value,
    checked: isChecked,
    isError,
    disabled: isDisabled,
    id,
    name,
    isRequired,
  }

  const handleOnClick = () => setIsMouseDown(true)

  const handleOnFocus = () => setIsFocus(true)

  const handleOnBlur = () => {
    setIsFocus(false)
    setIsMouseDown(false)
  }

  return (
    <StyledControl
      forwardedAs={forwardedAs || 'label'}
      isDisabled={isDisabled}
      {...rest}
    >
      <Input
        type="radio"
        {...attributes}
        isError={!!isError}
        isMouseDown={isMouseDown}
        onClick={handleOnClick}
        onChange={onChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      <Dot
        borderRadius="fullRounded"
        forwardedAs={'span' as IBox['forwardedAs']}
        isChecked={isChecked}
        isFocus={isFocused}
        isHover={isHover}
        isError={isError}
        isDisabled={isDisabled}
        onBlur={() => setIsMouseDown(false)}
      >
        {isDisabled && isChecked && (
          <DisabledDot
            forwardedAs="span"
            borderRadius="fullRounded"
            backgroundColor="grey0"
          />
        )}
      </Dot>
    </StyledControl>
  )
}

const StyledControl = styled(Box)<IRadioControl>`
  position: relative;
  display: inline-flex;
  align-items: center;

  ${p => css`
    ${p.isDisabled &&
    css`
      pointer-events: none;
    `};
  `};
`

const Dot = styled(Box)<IDot>`
  position: relative;
  cursor: pointer;
  ${setTransition(
    [
      'border-color',
      'border-width',
      'background-color',
      'box-shadow',
      'opacity',
    ],
    'productive',
  )};

  ${p => css`
    width: ${p.theme.space.s}px;
    height: ${p.theme.space.s}px;
    border: ${p.theme.borders.thinGrey20};

    &:hover {
      border-color: ${!p.isChecked &&
      p.theme.colors[p.isError ? 'alert100' : 'primary20']};
    }

    ${p.isHover &&
    !p.isChecked &&
    `border-color: ${p.theme.colors[p.isError ? 'alert100' : 'primary20']}`}

    ${p.isError &&
    !p.isHover &&
    !p.isChecked &&
    css`
      border-color: ${p.theme.colors.alert50};
    `};

    ${p.isChecked &&
    css`
      border-width: 5px;

      &:hover {
        border-color: ${p.theme.colors.primary100};
      }
    `};

    ${p.isChecked &&
    !p.isDisabled &&
    css`
      border-color: ${p.theme.colors.primary50};
    `};

    ${p.isDisabled &&
    css`
      background-color: ${p.theme.colors.grey05};
      border-color: ${p.theme.colors.grey20};
    `};

    ${p.isDisabled &&
    p.isChecked &&
    css`
      border-width: 1px;
      background-color: ${p.theme.colors.grey50};
    `};
  `};
`

const Input = styled.input<IInput>`
  position: absolute;
  opacity: 0;
  z-index: -1;

  ${p => css`
    &:active:not(:checked) + ${Dot} {
      background-color: ${p.theme.colors.primary05};
    }

    &:active:checked + ${Dot} {
      opacity: ${p.theme.aliases.defaultActiveOpacity};
    }

    &:focus + ${Dot} {
      box-shadow: ${!p.isMouseDown &&
      p.theme.focuses[p.isError ? 'error' : 'normal']};
    }

    &:focus:not(:checked) + ${Dot} {
      border-color: ${!p.isMouseDown && 'transparent'};
    }
  `};
`

const DisabledDot = styled(Box)<IBox>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${p => css`
    width: ${p.theme.space.xs / 2}px;
    height: ${p.theme.space.xs / 2}px;
  `};
`
