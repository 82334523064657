import styled, { css } from 'styled-components'

export const BaseIframe = styled.iframe<{
  iframeWidth?: number
  iframeHeight?: number
}>`
  ${p => css`
    width: ${p.iframeWidth ? `${p.iframeWidth + 20}px` : '100%'};
    height: ${p.iframeHeight
      ? `${p.iframeHeight + 20}px`
      : 'calc(100vh - 126px);'};
    border: none;
  `}
`
