import React, { FC, PropsWithChildren, useEffect } from 'react'
import styled from 'styled-components'
import { Box, Flex, Grid, IconButton, Text } from '@ads/front-ds'
import Loading from '@ads/front-core/atoms/Loading'

interface IPreviewFullscreen {
  isOpen: boolean
  headerLabel: string
  isLoading?: boolean
  prefixHeaderLabel?: string
  headerActions?: React.ReactElement
  onClose: (e?: React.MouseEvent<Element, MouseEvent>) => void
}

export const PreviewFullscreen: FC<PropsWithChildren<IPreviewFullscreen>> = ({
  isOpen,
  isLoading,
  prefixHeaderLabel,
  headerLabel,
  headerActions,
  onClose,
  children,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'initial'
    }

    return () => {
      document.documentElement.style.overflow = 'initial'
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <Wrapper>
      <HeaderWrapper columns={headerActions ? 3 : 2} px="xxl">
        <Flex alignItems="center">
          <IconButton
            name="20-arrow-left"
            variant="bareDark"
            mr="s"
            onClick={onClose}
          />
          {!!prefixHeaderLabel && (
            <Text color="grey50" fontSize="s" mr="xxxs">
              {prefixHeaderLabel}
            </Text>
          )}
          <Text color="grey100" fontSize="l" fontWeight="medium">
            {headerLabel}
          </Text>
        </Flex>
        {headerActions && <Flex justifyContent="center">{headerActions}</Flex>}
        <Flex justifyContent="flex-end">
          <IconButton name="20-close" onClick={onClose} />
        </Flex>
      </HeaderWrapper>
      {isLoading ? <Loading /> : children}
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  position: fixed;
  inset: 0;
  display: flex;
  flex-flow: column;
  background-color: ${p => p.theme.colors.inverted100};
  z-index: 999;
`

const HeaderWrapper = styled(Grid)`
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 82px;
  border-bottom: ${p => p.theme.borders.thinGrey20};
`
