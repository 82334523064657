import React, { FC, PropsWithChildren } from 'react'
import { Box, Flex, Text } from '@ads/front-ds'
import styled, { css } from 'styled-components'
import { lng } from '@ads/front-core/locales'
import { ICreationPreviewMethod } from './interfaces'
import { PreviewMethod } from '../interfaces'

const TextFakeData: FC<PropsWithChildren> = ({ children }) => (
  <Text as="td" fontSize="xs" fontWeight="medium" color="grey20">
    {children}
  </Text>
)

export const NativeLink: FC<
  ICreationPreviewMethod<PreviewMethod.NATIVE_LINK>
> = ({ creation }) => {
  return (
    <Container>
      <Title>{lng('campaign:previewCreation')}</Title>
      <Text color="grey50">
        {lng('campaign:nativeLink.previewDescription')}
      </Text>
      <Flex gap="xxxs" mt="l">
        <ActiveTab>{lng('campaign:nativeLink.main')}</ActiveTab>
        <Tab>{lng('notifications:notifications')}</Tab>
        <Tab>{lng('campaign:nativeLink.offers')}</Tab>
        <Tab>{lng('campaign:nativeLink.communities')}</Tab>
        <Tab>{lng('newsletters:newsletters')}</Tab>
      </Flex>
      <Flex>
        <Table>
          <thead>
            <TableRow>
              <Text
                as="td"
                fontSize="xs"
                color="grey20"
                styleCss={{ width: '75px' }}
              >
                {lng('campaign:nativeLink.advertisement')}
              </Text>
              <Text
                as="td"
                fontSize="xs"
                fontWeight="medium"
                styleCss={{ width: '150px' }}
              >
                {creation.content.clientName}
              </Text>
              <Text as="td" fontSize="xs" fontWeight="medium">
                <TopicWrapper>{creation.content.topic}</TopicWrapper>
              </Text>
            </TableRow>
          </thead>
          <tbody>
            {[...Array(3)].map((_, item) => (
              <TableRow key={`row-${item}`}>
                <td />
                <TextFakeData>Mail {item + 1}</TextFakeData>
                <TextFakeData>{lng('common:mailTitle')}</TextFakeData>
              </TableRow>
            ))}
          </tbody>
        </Table>
        <Text
          fontSize="xs"
          color="primary50"
          pl="s"
          styleCss={{ whiteSpace: 'nowrap', width: '120px' }}
        >
          {creation.content.callToAction}
        </Text>
      </Flex>
    </Container>
  )
}

const Container = styled(Box)`
  ${p => css`
    position: relative;
    padding: ${p.theme.space.l}px ${p.theme.space.s}px;
    border: ${p.theme.borders.thinGrey20};
    border-radius: ${p.theme.radii.rounded};
  `}
`

const Title = styled(Text)`
  ${p => css`
    position: absolute;
    top: -10px;
    left: -2px;
    background-color: ${p.theme.colors.inverted100};
    padding: 0 ${p.theme.space.xxs}px ${p.theme.space.xxs}px 0;
  `}
`

const ActiveTab = styled(Box)`
  ${p => css`
    display: flex;
    align-items: center;
    height: 34px;
    border-top-left-radius: ${p.theme.space.xxs}px;
    border-top-right-radius: ${p.theme.space.xxs}px;
    padding: 0 ${p.theme.space.l}px;
    color: #ababaf;
    background-color: #eaeaeb;
    font-size: ${p.theme.fontSizes.xs}px;
  `}
`

const Tab = styled(Box)`
  ${p => css`
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: ${p.theme.space.xxs}px;
    padding: 0 ${p.theme.space.l}px;
    margin-bottom: ${p.theme.space.xxxs}px;
    color: #ababaf;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
    font-size: ${p.theme.fontSizes.xs}px;
  `}
`

const Table = styled.table`
  border-collapse: collapse;
  border-top: ${p => p.theme.borders.thinGrey20};
  width: 586px;
`

const TableRow = styled.tr<{ disableHighlight?: boolean }>`
  border-bottom: ${p => p.theme.borders.thinGrey20};

  &:hover {
    box-shadow: none;
  }
`

const TopicWrapper = styled(Box)`
  display: block;
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
