/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestUpsertInvoiceComment } from '../models/RequestUpsertInvoiceComment'
import type { ResponseInvoiceComment } from '../models/ResponseInvoiceComment'
import type { ResponseInvoiceList } from '../models/ResponseInvoiceList'
import type { ResponseInvoicePdf } from '../models/ResponseInvoicePdf'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class InvoiceService {
  /**
   * Zwraca listę faktur vat
   *
   * @returns ResponseInvoiceList lista faktur vat
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getInvoiceList({
    dateFrom,
    dateTo,
    clientId,
  }: {
    /**
     * data od której będą zwracane faktury
     */
    dateFrom: string
    /**
     * data do której będą zwracane faktury
     */
    dateTo: string
    /**
     * identyfikator klienta
     */
    clientId: string
  }): CancelablePromise<ResponseInvoiceList | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoice',
      query: {
        dateFrom: dateFrom,
        dateTo: dateTo,
        clientId: clientId,
      },
    })
  }

  /**
   * Zwraca listę komentarzy do faktur
   *
   * @returns ResponseInvoiceComment lista komentarzy do faktur
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getInvoiceCommentList({
    clientId,
  }: {
    clientId: number
  }): CancelablePromise<Array<ResponseInvoiceComment> | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoice-comment/{clientId}',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * zapisuje komentarz do faktury
   *
   * @returns any Akceptacja regulaminu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static upsertInvoiceComment({
    clientId,
    requestBody,
  }: {
    clientId: number
    requestBody?: RequestUpsertInvoiceComment
  }): CancelablePromise<any | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/invoice-comment/{clientId}',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca plik pdf z fakturą
   *
   * @returns ResponseInvoicePdf plik PDF z fakturą VAT
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getInvoicePdf({
    clientId,
    invoiceId,
  }: {
    clientId: number
    /**
     * id faktury
     */
    invoiceId: number
  }): CancelablePromise<ResponseInvoicePdf | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoice-pdf/{invoiceId}',
      path: {
        invoiceId: invoiceId,
      },
      query: {
        clientId: clientId,
      },
    })
  }
}
