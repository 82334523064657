import {
  RequestCreationVariantEnable,
  ResponseCreationWithPreviewUrls,
  ResponseCreationContentNative,
} from '@ads/front-core/api'
import { TCreationVariant } from '@ads/front-core/interfaces'
import { lng } from '@ads/front-core/locales'

type CreationVariantOption = {
  name: string
  description: string
  variant: keyof RequestCreationVariantEnable
}

export const creationVariantsOptions: CreationVariantOption[] = [
  {
    name: lng('campaign:nativeAd.txtVariant'),
    description: lng('campaign:nativeAd.txtVariantDescription'),
    variant: 'txt',
  },
  {
    name: lng('campaign:nativeAd.txtGfxVariant'),
    description: lng('campaign:nativeAd.txtGfxVariantDescription'),
    variant: 'txt_gfx',
  },
  {
    name: lng('campaign:nativeAd.nativeBoxVariant'),
    description: lng('campaign:nativeAd.nativeBoxVariantDescription'),
    variant: 'native_box',
  },
]

export const getNativeCreationsVariants = (
  creations: ResponseCreationWithPreviewUrls[],
): TCreationVariant[] =>
  creations.reduce<TCreationVariant[]>((acc, creation) => {
    const { id, type } = creation.content
    if (type === 'NATIVE') {
      const {
        activeVariants,
      } = creation.content as ResponseCreationContentNative
      return [...acc, { creationId: id, variants: activeVariants }]
    }
    return acc
  }, [])
