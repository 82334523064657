export const onLoad = (iframeRef: HTMLIFrameElement) => {
  if (iframeRef) {
    try {
      iframeRef.style.height = `${
        iframeRef.contentWindow.document.body.scrollHeight + 20
      }px`

      iframeRef.style.width = `${
        iframeRef.contentWindow.document.body.scrollWidth + 20
      }px`
    } catch (e) {
      console.warn('Iframe onLoad resize', e)
    }
  }
}
