import {
  CreationType,
  ResponseCreationContentBanner,
  ResponseCreationContentNative,
  ResponseCreationContentRichMedia,
  ResponseCreationNativeLink,
  ResponseCreationWithPreviewUrls,
} from '@ads/front-core/api'
import { Campaign, TCreationVariant } from '@ads/front-core/interfaces'
import { getWidthHeight } from '@ads/front-core/utils'
import {
  IPreviewCreation,
  PreviewMethod,
} from '@ads/front-core/components/CreationPreview'
import { creationVariantsOptions } from './nativeCampaignCreationVariants'

type GenerateMetodAttrs = ({
  campaignId,
  creation,
  creationVariants,
}: {
  campaignId: Campaign['id']
  creation: ResponseCreationWithPreviewUrls
  creationVariants: TCreationVariant[]
}) => IPreviewCreation[]

const simple: GenerateMetodAttrs = ({ creation, campaignId }) => [
  {
    key: `${creation.id}-simple`,
    creationId: creation.id,
    name: creation.name,
    previewMethod: PreviewMethod.IFRAME_URL,
    url: `/api/campaign/${campaignId}/preview?noleap&creationId=${creation.id}`,
  },
]

const simpleWithPreview: GenerateMetodAttrs = ({ creation, campaignId }) => {
  const content = creation.content as ResponseCreationContentBanner
  return [
    {
      key: `${creation.id}-simple-preview`,
      creationId: creation.id,
      label: creation.name,
      previewMethod: PreviewMethod.IFRAME_URL,
      url: `/api/campaign/${campaignId}/preview?noleap&creationId=${creation.id}`,
      size: content.size,
      previewExternalUrl: creation.previewUrls.banner,
    },
  ]
}

const mailing: GenerateMetodAttrs = ({ creation, campaignId }) => [
  {
    key: `${creation.id}-mailing`,
    creationId: creation.id,
    name: creation.name,
    previewMethod: PreviewMethod.IFRAME_URL,
    url: `/api/campaign/${campaignId}/preview?noleap&creationId=${creation.id}`,
    isFullWindowSize: true,
  },
]

const native: GenerateMetodAttrs = ({
  campaignId,
  creation,
  creationVariants,
}) => {
  const creations: IPreviewCreation[] = []
  const content = creation.content as ResponseCreationContentNative
  creationVariantsOptions.forEach(creationVariant => {
    const variant = creationVariant.variant

    const activeVariants =
      creationVariants?.find(variant => variant.creationId === creation.id)
        ?.variants || content.activeVariants

    if (activeVariants[variant]) {
      const url = `/api/campaign/${campaignId}/preview?noleap&creationId=${creation.id}&creationVariant=${variant}`
      creations.push({
        key: `${creation.id}-${variant}`,
        creationId: creation.id,
        label: creationVariantsOptions.find(item => item.variant === variant)
          ?.name,
        previewMethod: PreviewMethod.IFRAME_URL,
        url,
        previewExternalUrl: creation.previewUrls[variant],
      })
    }
  })
  return creations
}

const richMedia: GenerateMetodAttrs = ({ creation }) => {
  const content = creation.content as ResponseCreationContentRichMedia
  return [
    {
      key: `${creation.id}-rich-media`,
      creationId: creation.id,
      label: creation.name,
      previewMethod: PreviewMethod.FETCH_HTML,
      requestBody: {
        type: creation.content.type,
        content: {
          subtype: content.subtype,
          media: content.media,
          ...getWidthHeight(content.size),
        },
      },
    },
  ]
}

const nativeLink: GenerateMetodAttrs = ({ creation }) => {
  const content = creation.content as ResponseCreationNativeLink
  return [
    {
      key: `${creation.id}-native-link`,
      creationId: creation.id,
      label: creation.name,
      previewMethod: PreviewMethod.NATIVE_LINK,
      content: {
        type: creation.content.type,
        clientName: content.clientName,
        topic: content.topic,
        callToAction: content.callToAction,
      },
    },
  ]
}

const GenerateCreationPreview: Partial<
  Record<CreationType, GenerateMetodAttrs>
> = {
  MAILING_DYNAMIC: mailing,
  STUDIO: simple,
  BANNER: simpleWithPreview,
  CODE: simpleWithPreview,
  HTML: simpleWithPreview,
  NATIVE: native,
  RICH_MEDIA: richMedia,
  NATIVE_LINK_DIRECT: nativeLink,
}

export const generateCreationsPreview = ({
  campaignId,
  creations,
  creationVariants,
}: {
  campaignId: Campaign['id']
  creations: ResponseCreationWithPreviewUrls[]
  creationVariants?: TCreationVariant[]
}): IPreviewCreation[] => {
  const previewCreations: IPreviewCreation[] = []

  creations.forEach(creation => {
    const generateCreationPreviewMethod =
      GenerateCreationPreview[creation.content.type]
    if (typeof generateCreationPreviewMethod === 'function') {
      const previews = generateCreationPreviewMethod({
        campaignId,
        creation,
        creationVariants,
      })
      previews?.forEach(preview => previewCreations.push(preview))
    }
  })

  return previewCreations
}
