import {
  RequestCreationContentNativeLink,
  RequestPreviewCreationContent,
} from '@ads/front-core/api'

export enum PreviewMethod {
  IFRAME_URL,
  IFRAME_HTML,
  FETCH_HTML,
  NATIVE_LINK,
}

export type IPreviewCreation = {
  key: string
  creationId?: number
  label?: string
  previewExternalUrl?: string
  size?: string
  isFullWindowSize?: boolean
} & (
  | {
      previewMethod: PreviewMethod.FETCH_HTML
      requestBody?: RequestPreviewCreationContent
    }
  | {
      previewMethod: PreviewMethod.IFRAME_HTML
      html: string
    }
  | {
      previewMethod: PreviewMethod.IFRAME_URL
      url: string
    }
  | {
      previewMethod: PreviewMethod.NATIVE_LINK
      content: RequestCreationContentNativeLink
    }
)
