import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { CheckboxControl, RadioControl, Flex, Text } from '@ads/front-ds'

interface ICheckboxButton {
  asRadio?: boolean
  label: string
  Icon?: React.ReactElement
  fixedWidth?: boolean
  isChecked: boolean
  isDisabled?: boolean
  AppendComponent?: React.ReactElement
  onChange: () => void
}

export const CheckboxButton: FC<ICheckboxButton> = ({
  asRadio,
  label,
  Icon,
  fixedWidth,
  isChecked,
  isDisabled,
  AppendComponent,
  onChange,
}) => {
  const onClick = (
    e?:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    e?.preventDefault()
    onChange()
  }

  return (
    <Wrapper
      isDisabled={isDisabled}
      isChecked={isChecked}
      fixedWidth={fixedWidth}
      onClick={isDisabled ? null : onClick}
    >
      <Flex alignItems="center" gap="s">
        {asRadio ? (
          <RadioControl
            isDisabled={isDisabled}
            isChecked={isChecked}
            onChange={isDisabled ? null : onClick}
          />
        ) : (
          <CheckboxControl
            isDisabled={isDisabled}
            isChecked={isChecked}
            onChange={isDisabled ? null : onClick}
          />
        )}
        {Icon}
        <Text color="grey100" fontWeight="medium">
          {label}
        </Text>
      </Flex>
      {AppendComponent}
    </Wrapper>
  )
}

const Wrapper = styled.button<{
  isChecked?: boolean
  fixedWidth?: boolean
  isDisabled?: boolean
}>`
  ${p => css`
    display: flex;
    gap: ${p.theme.space.s}px;
    align-items: center;
    justify-content: space-between;
    background: ${p.isChecked && !p.isDisabled
      ? p.theme.colors.primary05
      : p.isDisabled
      ? p.theme.colors.grey0
      : p.theme.colors.inverted100};
    border: ${p.isChecked && !p.isDisabled
      ? p.theme.borders.thinPrimary20
      : p.theme.borders.thinGrey20};
    border-radius: ${p.theme.radii.rounded};
    box-sizing: border-box;
    padding: ${p.theme.space.xs}px;
    width: ${p.fixedWidth ? '263px' : 'auto'};
    cursor: ${p.isDisabled ? 'not-allowed' : 'pointer'};
  `}
`
