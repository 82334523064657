/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestNewInvoice } from '../models/RequestNewInvoice'
import type { ResponseAdvOrderOwners } from '../models/ResponseAdvOrderOwners'
import type { ResponseBankierCampaign } from '../models/ResponseBankierCampaign'
import type { ResponseBankierClient } from '../models/ResponseBankierClient'
import type { ResponseCreationWithPreviewUrls } from '../models/ResponseCreationWithPreviewUrls'
import type { ResponseMetricsClientStats } from '../models/ResponseMetricsClientStats'
import type { ResponsePublicGetAllPixels } from '../models/ResponsePublicGetAllPixels'
import type { ResponsePublicUserInfo } from '../models/ResponsePublicUserInfo'
import type { ResponseReleaseVersion } from '../models/ResponseReleaseVersion'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PublicService {
  /**
   * zwraca listę pixeli wraz z danymi właściceli zlecenia reklamowego
   *
   * @returns ResponseAdvOrderOwners lista właścicieli zleceń reklamowych
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static advOrderOwners(): CancelablePromise<
    ResponseAdvOrderOwners | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/public/shop/adv-order-owners',
    })
  }

  /**
   * zwraca listę wszystkich pixeli (pomija zablokowanych klientów i ukryte sklepy)
   *
   * @returns ResponsePublicGetAllPixels lista wszystkich pixeli z informacją czy są albo były uruchamiane na nim kampanie
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getPixelsPublic({
    page,
    limit,
  }: {
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
  }): CancelablePromise<ResponsePublicGetAllPixels | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/public/pixels',
      query: {
        page: page,
        limit: limit,
      },
    })
  }

  /**
   * Wysyła do użytkownika informacje o nowej fatkurze lub korekcie
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static newInvoice({
    authorization,
    requestBody,
  }: {
    authorization?: string
    /**
     * Dane fakury
     */
    requestBody?: RequestNewInvoice
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/public/new-invoice/',
      headers: {
        Authorization: authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Pobiera wersję aktualnie działającej wersji
   *
   * @returns ResponseReleaseVersion odpowiedź z wersją wydania
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static releaseVersion(): CancelablePromise<
    ResponseReleaseVersion | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/public/release',
    })
  }

  /**
   * Zwraca informacje o pixelach przypisanych do danego hbpId
   * @returns ResponsePublicUserInfo Informacje o użytkowniku
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getUserPixels({
    md5Email,
    authorization,
  }: {
    md5Email: string
    authorization?: string
  }): CancelablePromise<ResponsePublicUserInfo | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/public/user-pixels/{md5(email)}',
      path: {
        'md5(email)': md5Email,
      },
      headers: {
        Authorization: authorization,
      },
    })
  }

  /**
   * zwraca informacje o wybranej kampanii
   *
   * @returns ResponseBankierCampaign infomacje o kampanii
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCampaignPublic({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<ResponseBankierCampaign | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/public/bankier/campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * Zwraca finansowe dane klienta
   *
   * @returns ResponseBankierClient wartość salda
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getClientPublic({
    hbpId,
  }: {
    hbpId: string
  }): CancelablePromise<ResponseBankierClient | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/public/bankier/client/{hbpId}',
      path: {
        hbpId: hbpId,
      },
    })
  }

  /**
   * Zwraca podgląd kampanii dowolnego typu
   *
   * @returns string Podgląd kampanii
   * @throws ApiError
   */
  public static getCampaignPreviewPublic({
    campaignId,
    creationId,
    creationVariant,
  }: {
    campaignId: number
    /**
     * identyfikator kreacji reklamowej
     */
    creationId?: number
    creationVariant?: 'txt' | 'txt_gfx' | 'native_box' | 'related_content'
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/public/campaign/{campaignId}/preview',
      path: {
        campaignId: campaignId,
      },
      query: {
        creationId: creationId,
        creationVariant: creationVariant,
      },
    })
  }

  /**
   * Zwraca statystyki klientów i sklepów dla Metricsa
   *
   * @returns ResponseMetricsClientStats Statystyki klienta dla metrics
   * @throws ApiError
   */
  public static getClientStatsPublic(): CancelablePromise<
    Array<ResponseMetricsClientStats>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/public/metrics/clients',
    })
  }

  /**
   * Pobiera pełną listę kreacji dla kampanii
   * @returns ResponseCreationWithPreviewUrls Podgląd kampanii
   * @throws ApiError
   */
  public static creationsByCampaign({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<Array<ResponseCreationWithPreviewUrls>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/public/campaign/{campaignId}/creation/',
      path: {
        campaignId: campaignId,
      },
    })
  }
}
