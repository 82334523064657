import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Flex, TextButton, Tooltip } from '@ads/front-ds'
import { Separator } from '@ads/front-core/atoms'
import { lng } from '@ads/front-core/locales'
import { copyToClipboard } from '@ads/front-core/utils/copyToClipboard'

interface ICreationPreviewActions {
  previewExternalUrl?: string
  publicPreviewUrl?: string
  showLabel?: boolean
}

const CreationPreviewActions: FC<ICreationPreviewActions> = ({
  previewExternalUrl,
  publicPreviewUrl,
  showLabel,
}) => {
  const dispatch = useDispatch()

  const handleCopy = (content: string) => copyToClipboard(content, dispatch)

  const openInNewTab = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <Flex gap="xxs" alignItems="center">
      {!!previewExternalUrl && (
        <Tooltip
          text={lng('campaign:openPreviewInExternalSource')}
          placement="left"
          hide={showLabel}
        >
          <TextButton
            iconBefore="20-link-external"
            onClick={() => openInNewTab(previewExternalUrl)}
          >
            {showLabel && lng('campaign:openPreviewInExternalSource')}
          </TextButton>
        </Tooltip>
      )}
      {previewExternalUrl && publicPreviewUrl && <VerticalLine />}
      {!!publicPreviewUrl && (
        <Tooltip
          text={lng('campaign:copyLink')}
          placement="left"
          hide={showLabel}
        >
          <TextButton
            iconBefore="20-copy"
            onClick={() => handleCopy(publicPreviewUrl)}
            ml="xxs"
          >
            {showLabel && lng('campaign:copyLink')}
          </TextButton>
        </Tooltip>
      )}
    </Flex>
  )
}

const VerticalLine = styled(Separator)`
  height: 26px;
`

export default CreationPreviewActions
