import React, { FC } from 'react'
import { PreviewMethod } from '@ads/front-core/components/CreationPreview'
import { ICreationPreviewMethod } from './interfaces'
import { FetchHtml } from './FetchHtml'
import { IframeHtml } from './IframeHtml'
import { IframeUrl } from './IframeUrl'
import { NativeLink } from './NativeLink'

export const CreationPreviewMethod: FC<ICreationPreviewMethod<unknown>> = ({
  creation,
  creationRef,
}) => {
  switch (creation.previewMethod) {
    case PreviewMethod.IFRAME_URL:
      return <IframeUrl creationRef={creationRef} creation={creation} />
    case PreviewMethod.IFRAME_HTML:
      return <IframeHtml creationRef={creationRef} creation={creation} />
    case PreviewMethod.FETCH_HTML:
      return <FetchHtml creationRef={creationRef} creation={creation} />
    case PreviewMethod.NATIVE_LINK:
      return <NativeLink creation={creation} />
    default:
      console.error('Unknown CreationPreviewMethod type', creation)
      return null
  }
}
