import React, { FC } from 'react'
import { getWidthHeight } from '@ads/front-core/utils'
import { PreviewMethod } from '../interfaces'
import { ICreationPreviewMethod } from './interfaces'
import { BaseIframe } from './BaseIframe'
import { onLoad } from './utils'

export const IframeUrl: FC<
  ICreationPreviewMethod<PreviewMethod.IFRAME_URL>
> = ({ creation, creationRef }) => {
  const creationDimensions = getWidthHeight(creation.size)

  return (
    <BaseIframe
      ref={creationRef}
      onLoad={
        !creationDimensions?.width && !creation.isFullWindowSize
          ? () => onLoad(creationRef?.current)
          : null
      }
      src={creation.url}
      iframeWidth={creationDimensions?.width}
      iframeHeight={creationDimensions?.height}
    />
  )
}
