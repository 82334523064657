import { PreviewMethod } from '@ads/front-core/components/CreationPreview'
import { IPreviewCreation } from './interfaces'

export const withPrevieHeader = (creation: IPreviewCreation): boolean =>
  !!creation.size || !!creation.label || !!creation.previewExternalUrl

function appendValue(
  key: string,
  value: string | number,
  search: URLSearchParams,
): string {
  if (search.get(key)) {
    return `${search.get(key)},${value}`
  }
  return String(value)
}

export function generatePublicPreviewSearchUrl(
  campaignId: number,
  creations: IPreviewCreation[],
): URLSearchParams {
  const search = new URLSearchParams()
  if (campaignId) {
    search.set('campaignId', String(campaignId))
  }

  creations?.forEach(creation => {
    if (creation.creationId) {
      search.set(
        'creationId',
        appendValue('creationId', creation.creationId, search),
      )
    }
    if (creation.previewMethod === PreviewMethod.FETCH_HTML) {
      search.set('rb', JSON.stringify(creation.requestBody))
    } else if (creation.size) {
      search.set(
        'creationFormat',
        appendValue('creationFormat', creation.size, search),
      )
    }
  })

  return search
}
