import { createAction } from '@reduxjs/toolkit'
import { PAYMENT_METHOD } from 'components/Payment/interface'
import { AppState } from '../../appState'

export const setCampaignPaymentMethod = createAction<PAYMENT_METHOD>(
  'campaign/setCampaignPaymentMethod',
)

export const setCampaignPaymentMethodReducer = (
  state: AppState['campaign'],
  action: ReturnType<typeof setCampaignPaymentMethod>,
): void => {
  state.selectedPaymentMethod = action.payload
}
