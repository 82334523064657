import { rgba } from 'polished'
import React, { FC, useEffect, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled, { css } from 'styled-components'

import { useNanoid } from '../../../utility'
import { Box } from '../../Box'
import { Button, IconButton } from '../../Buttons'
import { Text } from '../../Text'
import { TextButton } from '../../Buttons/TextButton'

import { IDialog, IModal, ITitle } from './types'

export const Dialog: FC<React.PropsWithChildren<IDialog>> = ({
  isShown,
  title,
  children,
  actions,
  size = 'm',
  onCloseClick,
  onOverlayClick,
  ...rest
}) => {
  const [showContainer, setShowContainer] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [ids] = useNanoid(Array.isArray(actions) ? actions.length : 1)

  useEffect(() => {
    if (isShown) {
      setShowContainer(true)
      setShowModal(true)
    } else {
      setShowModal(false)
      setTimeout(() => setShowContainer(false), 100)
    }
  }, [isShown])

  return (
    <TransitionGroup>
      <CSSTransition
        in={showContainer}
        timeout={350}
        classNames="fade"
        unmountOnExit
      >
        <Overlay
          isShown={showContainer}
          {...rest}
          p="xxl"
          onClick={onOverlayClick}
        >
          <Container>
            <CSSTransition
              in={showModal}
              timeout={350}
              classNames="slide"
              unmountOnExit
            >
              <Modal
                p="m"
                borderRadius="rounded"
                size={size}
                onClick={e => e?.stopPropagation()}
                role="dialog"
                aria-modal="true"
                aria-labelledby="modalTitle"
              >
                <Header>
                  <Title
                    fontSize="xl"
                    lineHeight="l"
                    fontWeight="bold"
                    pr="m"
                    id="modalTitle"
                  >
                    {title}
                  </Title>
                  {onCloseClick && (
                    <Close
                      aria-label="Zamknij"
                      my="xxxs"
                      variant="bareDark"
                      name="20-close"
                      onClick={onCloseClick}
                    />
                  )}
                </Header>
                {children && <Box mt="m">{children}</Box>}
                {actions && (
                  <Footer mt="l">
                    {Array.isArray(actions) ? (
                      actions.map((button, index) =>
                        button.variant === 'text' ? (
                          <TextButton
                            isDisabled={button.isDisabled}
                            ml={index > 0 ? 's' : 'none'}
                            onClick={button.onClick}
                            key={ids[index]}
                          >
                            {button.label}
                          </TextButton>
                        ) : (
                          <Button
                            variant={button.variant}
                            isDisabled={button.isDisabled}
                            ml={index > 0 ? 's' : 'none'}
                            onClick={button.onClick}
                            key={ids[index]}
                          >
                            {button.label}
                          </Button>
                        ),
                      )
                    ) : actions.variant === 'text' ? (
                      <TextButton onClick={actions.onClick}>
                        {actions.label}
                      </TextButton>
                    ) : (
                      <Button
                        variant={actions.variant}
                        onClick={actions.onClick}
                      >
                        {actions.label}
                      </Button>
                    )}
                  </Footer>
                )}
              </Modal>
            </CSSTransition>
          </Container>
        </Overlay>
      </CSSTransition>
    </TransitionGroup>
  )
}

const Overlay = styled(Box)<IModal>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  ${p => css`
    background-color: ${rgba(p.theme.components.dialogOverlayBackground, 0.3)};
    z-index: ${p.zIndex};
    visibility: ${p.isShown ? 'visible' : 'hidden'};

    &.fade-enter {
      opacity: 0;
      visibility: hidden;
    }

    &.fade-enter-active {
      opacity: 1;
      visibility: visible;
      transition: visiblity ${p.theme.motion.expressive},
        opacity ${p.theme.motion.expressive};
    }

    &.fade-exit {
      opacity: 1;
      visibility: visible;
    }

    &.fade-exit-active {
      opacity: 0;
      visibility: hidden;
      transition: visiblity ${p.theme.motion.expressive},
        opacity ${p.theme.motion.expressive};
    }
  `};
`

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
`

const Modal = styled(Box)<IModal>`
  position: relative;

  ${p => css`
    width: ${p.size === 'l'
      ? p.theme.components.dialogSizeLarge
      : p.theme.components.dialogSizeMedium}px;
    background-color: ${p.theme.components.dialogModalBackground};
    color: ${p.theme.components.dialogModalColor};

    &.slide-enter {
      opacity: 0;
      transform: translate3d(0px, -40px, 0px);
    }

    &.slide-enter-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity ${p.theme.motion.expressive},
        transform ${p.theme.motion.expressive};
    }

    &.slide-exit {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &.slide-exit-active {
      opacity: 0;
      transform: translate3d(0px, -40px, 0px);
      transition: opacity ${p.theme.motion.expressive},
        transform ${p.theme.motion.expressive};
    }
  `};
`

const Close = styled(IconButton)`
  margin-left: auto;
`

const Header = styled(Box)`
  display: flex;
  align-items: flex-start;
`

const Title = styled(Text)<ITitle>``

const Footer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
