import { useEffect, useState } from 'react'
import {
  CreationService,
  RequestPreviewCreationContent,
} from '@ads/front-core/api'

export const useFetchPreviewHTML = (
  requestBody: RequestPreviewCreationContent,
): {
  isLoading: boolean
  previewHTML: string
} => {
  const [isLoading, setIsLoading] = useState(false)
  const [previewHTML, setPreviewHTML] = useState('')

  const fetchPreview = async () => {
    setIsLoading(true)

    try {
      const res = await CreationService.previewCreationContent({
        requestBody,
      })
      setPreviewHTML(res)
    } catch (e) {
      console.error(e)
    }

    setIsLoading(false)
  }

  const effectKey = JSON.stringify(requestBody)

  useEffect(() => {
    fetchPreview()
  }, [effectKey])

  return { previewHTML, isLoading }
}
