import React, { FC } from 'react'
import { getWidthHeight } from '@ads/front-core/utils'
import { PreviewMethod } from '../interfaces'
import { ICreationPreviewMethod } from './interfaces'
import { BaseIframe } from './BaseIframe'
import { onLoad } from './utils'

export const IframeHtml: FC<
  ICreationPreviewMethod<PreviewMethod.IFRAME_HTML>
> = ({ creation, creationRef }) => {
  const creationDimensions = getWidthHeight(creation.size)

  return (
    <BaseIframe
      ref={creationRef}
      onLoad={
        !creationDimensions?.width && !creation.isFullWindowSize
          ? () => onLoad(creationRef?.current)
          : null
      }
      sandbox="allow-same-origin allow-scripts"
      srcDoc={creation.html}
      iframeWidth={creationDimensions?.width}
      iframeHeight={creationDimensions?.height}
    />
  )
}
