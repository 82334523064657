import React, { FC, RefObject } from 'react'
import styled, { css } from 'styled-components'
import { Flex, Text } from '@ads/front-ds'
import { Separator } from '@ads/front-core/atoms'
import { getWidthHeight } from '@ads/front-core/utils'
import CreationPreviewActions from './CreationPreviewActions'
import { IPreviewCreation } from './interfaces'
import { CreationPreviewMethod } from './CreationPreviewMethod'
import { withPrevieHeader } from './utils'

interface ICreationPreviewItem {
  creation: IPreviewCreation
  creationRef: RefObject<HTMLIFrameElement>
}

export const CreationPreviewItem: FC<ICreationPreviewItem> = ({
  creation,
  creationRef,
}) => {
  const creationDimensions = getWidthHeight(creation.size)

  return (
    <Preview
      key={creation.key}
      flexDirection="column"
      fitHeight={!creationDimensions}
    >
      {withPrevieHeader(creation) && (
        <PreviewHeader justifyContent="space-between" alignItems="center">
          {(creation.size || creation.label) && (
            <Flex flexDirection="row" gap="s">
              {!!creation.size && (
                <Text fontWeight="medium">{creation.size}</Text>
              )}
              {creation.size && creation.label && <Separator />}
              {!!creation.label && (
                <Text fontWeight="medium">{creation.label}</Text>
              )}
            </Flex>
          )}
          {!!creation.previewExternalUrl && (
            <CreationPreviewActions
              previewExternalUrl={creation.previewExternalUrl}
              publicPreviewUrl={creation.previewExternalUrl}
            />
          )}
        </PreviewHeader>
      )}
      <IFrameWrapper justifyContent="center" fitHeight={!creationDimensions}>
        <CreationPreviewMethod creationRef={creationRef} creation={creation} />
      </IFrameWrapper>
    </Preview>
  )
}

const Preview = styled(Flex)<{ fitHeight?: boolean }>`
  ${p => css`
    position: relative;
    width: 100%;
    margin-bottom: 44px;

    ${p.fitHeight &&
    css`
      flex: 1;
    `}

    &::after {
      content: '';
      position: absolute;
      bottom: -44px;
      width: 100%;
      height: 1px;
      background-color: ${p.theme.colors.grey20};
    }

    &:last-of-type {
      &::after {
        content: none;
      }
    }
  `}
`

const IFrameWrapper = styled(Flex)<{ fitHeight?: boolean }>`
  ${p => css`
    ${p.fitHeight &&
    css`
      flex: 1;
    `}
  `}
`

const PreviewHeader = styled(Flex)`
  margin: 28px 0;
`
