import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { put, select, takeEvery } from 'redux-saga/effects'
import { IInlineBanner } from '@ads/front-ds'
import { ADSApiError } from '@ads/front-core/interfaces'
import { lng } from '@ads/front-core/locales'
import { setDemoDialogVisibility } from '@ads/front-core/store/reducers/app/setDemoDialogVisibility'
import { getIsDemo } from '@ads/front-core/store/selectors/getIsDemo'
import { logError } from '@ads/front-core/utils/logError'
import { banner } from './bannerSaga'

interface ApiErrorProps {
  error: ADSApiError
  label?: string
  variant?: IInlineBanner['variant']
}

export type ApiErrorSaga = ReturnType<
  PayloadActionCreator<ApiErrorProps, string>
>

export function* sagaApiError(): Generator {
  yield takeEvery(apiError.type, apiErrorSaga)
}

export const apiError = createAction<ApiErrorProps>('saga/apiError')

export function* apiErrorSaga({
  payload: { error, label, variant },
}: ApiErrorSaga): Generator {
  logError(error)

  const isDemo = yield select(getIsDemo)

  if (error?.status === 401) {
    window.location.href = `/sso/auth?backURL=${encodeURIComponent(
      window.document.location.toString(),
    )}`
    return
  }

  if (isDemo && error?.status === 423) {
    yield put(setDemoDialogVisibility(true))
    return
  }

  const bannerLabel =
    label ||
    error?.body?.message ||
    (!error?.body?.logId && error
      ? error?.message
      : lng('common:errorSystem', { logId: error?.body?.logId }))

  yield put(
    banner({
      description: bannerLabel,
      variant: variant || 'error',
    }),
  )
}
