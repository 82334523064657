import React, { FC, ReactElement, createRef } from 'react'
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import { Box, Flex } from '@ads/front-ds'
import { PreviewFullscreen } from '@ads/front-core/components/PreviewFullscreen'
import { lng } from '@ads/front-core/locales'
import CreationPreviewActions from './CreationPreviewActions'
import { IPreviewCreation } from './interfaces'
import { CreationPreviewItem } from './CreationPreviewItem'
import { generatePublicPreviewSearchUrl } from './utils'

interface ICreationPreview {
  campaignId?: number
  creations: IPreviewCreation[]
  headline?: ReactElement
  hideHeaderActions?: boolean
  onClose: () => void
}

export const CreationPreview: FC<ICreationPreview> = ({
  campaignId,
  creations,
  headline,
  hideHeaderActions,
  onClose,
}) => {
  const { protocol, host } = useLocation()
  const iframeNativeRefs = creations.map(() => createRef<HTMLIFrameElement>())

  const publicPreviewSearchUrl = generatePublicPreviewSearchUrl(
    campaignId,
    creations,
  )

  const showHeaderActions =
    !hideHeaderActions &&
    (publicPreviewSearchUrl ||
      (creations?.length === 1 && creations[0]?.previewExternalUrl))

  return (
    <PreviewFullscreen
      isOpen
      headerLabel={lng('campaign:previewCreation')}
      prefixHeaderLabel={`${lng('campaign:creations').toLocaleLowerCase()} /`}
      onClose={onClose}
      headerActions={
        showHeaderActions ? (
          <CreationPreviewActions
            previewExternalUrl={creations?.[0]?.previewExternalUrl}
            publicPreviewUrl={
              publicPreviewSearchUrl
                ? `${protocol}//${host}/creation/preview/?${publicPreviewSearchUrl.toString()}`
                : null
            }
            showLabel
          />
        ) : null
      }
    >
      <Container>
        <Wrapper
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Headline>{headline}</Headline>
          {creations?.map((creation, i) => (
            <CreationPreviewItem
              key={creation.key}
              creation={creation}
              creationRef={iframeNativeRefs[i]}
            />
          ))}
        </Wrapper>
      </Container>
    </PreviewFullscreen>
  )
}

const Container = styled(Box)`
  overflow-y: auto;
  flex: 1;
`

const Wrapper = styled(Flex)`
  width: 1134px;
  min-height: 100%;
  margin: 0 auto;
`

const Headline = styled.div`
  width: 100%;
`
